<template>
  <b-container fluid>
    <b-card :title="kurumName">
      <hr/>
      <b-row>
        <b-col cols="4">
          <b-form-group
              label="Kurum Tam Ünvan"
              label-for="basicInput"
          >
            <b-form-input
                v-model="tamUnvan"
                id="basicInput"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Kurum Kısa Ünvan"
              label-for="basicInput"
          >
            <b-form-input
                v-model="unvan"
                id="basicInput"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="İl"
              label-for="basicInput"
          >
            <b-form-input
                v-model="il"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="İlçe"
              label-for="basicInput"
          >
            <b-form-input
                v-model="ilce"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Telefon"
              label-for="basicInput"
          >
            <b-form-input
                v-model="telefon"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="E-Posta"
              label-for="basicInput"
          >
            <b-form-input
                v-model="ePosta"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Kurum Yetkilisi"
              label-for="basicInput"
          >
            <b-form-input
                v-model="kurumYetkilisi"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Kurum Yetkilisi Telefon"
              label-for="basicInput"
          >
            <b-form-input
                v-model="yetkiliTelefon"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Adres"
              label-for="basicInput"
          >
            <b-form-input
                v-model="adres"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Web Site Adresi"
              label-for="basicInput"
          >
            <b-form-input
                v-model="siteURL"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Arvas Api KEY"
              label-for="basicInput"
          >
            <b-form-input
                v-model="apiKey"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="CDN"
              label-for="basicInput"
          >
            <b-form-input
                v-model="CDN"
                id="basicInput"

            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="col-12-padding">
          <b-button @click="kurumUpdate" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
            <feather-icon
                icon="SaveIcon"
                class="mr-50"
            />
            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BInputGroup,
  BCard,
  BRow,
  BCol,
  BContainer,
  BNavbarNav,
  BNavItem,
  BTooltip,
  BNavItemDropdown,
  BFormInput,
  BDropdownItem,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BFormGroup,
    BInputGroup,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BFormSelectOption,
    vSelect,
    BNavbarNav,
    BNavItem,
    BTooltip,
    BNavItemDropdown,
    BFormInput,
    VuePerfectScrollbar,
    BDropdownItem,
  },
  data() {
    return {
      firmaKodu: '',
      tamUnvan: '',
      unvan: '',
      il: '',
      ilce: '',
      telefon: '',
      ePosta: '',
      kurumYetkilisi: '',
      yetkiliTelefon: '',
      adres: '',
      siteURL: '',
      apiKey: '',
      CDN: '',
    }
  },
  computed: {
    kurumName() {
      return store.getters.getKurumList.find(x => x.firmaKodu === this.$route.params.id).unvan
    },
  },
  watch: {
    '$route.params.id': function (newVal) {
    },
  },
  methods: {
    kurumUpdate() {
      store.dispatch('kurumUpdateSet', {
        firmaKodu: this.firmaKodu,
        tamUnvan: this.tamUnvan,
        unvan: this.unvan,
        il: this.il,
        ilce: this.ilce,
        telefon: this.telefon,
        ePosta: this.ePosta,
        kurumYetkilisi: this.kurumYetkilisi,
        yetkiliTelefon: this.yetkiliTelefon,
        adres: this.adres,
        siteURL: this.siteURL,
        apiKey: this.apiKey,
        CDN: this.CDN,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status === 200) {
              this.$router.push('/')
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
  },
  created() {
    store.state.firmaPreview = false
    store
        .dispatch('kurumUpdateGet')
        .then((res, position = 'bottom-right') => {
          this.tamUnvan = res.edit.webFirma.tamUnvan
          this.unvan = res.edit.webFirma.unvan
          this.il = res.edit.webFirma.il
          this.ilce = res.edit.webFirma.ilce
          this.telefon = res.edit.webFirma.telefon
          this.ePosta = res.edit.webFirma.ePosta
          this.kurumYetkilisi = res.edit.webFirma.kurumYetkilisi
          this.yetkiliTelefon = res.edit.webFirma.yetkiliTelefon
          this.adres = res.edit.firma.adres
          this.siteURL = res.edit.webFirma.siteURL
          this.apiKey = res.edit.firma.apiKey
          this.CDN = res.edit.webFirma.CDN
          this.firmaKodu = res.edit.webFirma.firmaKodu
          const mesaj = store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.custom-select {
  width: 150% !important;
  background-color: #65c18c !important;
  color: white !important;
  border-color: transparent;
}

.custom-select option {
  width: 150% !important;
  background-color: white !important;
  color: black !important;
}

.default-select {
  color: black !important;
}

.col-12-padding {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
</style>
